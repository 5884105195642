<template>
    <div
        v-if="isShown && !fieldData.isDuplicate"
        class="flex flex-col scrollToElement relative"
        :class="['form-field', {
            'form-field--invalid': isError,
            'form-field--consent': getFieldConfig.customTemplate === 'consent',
            'bg-gray-100 p-5 rounded-xl': parentData.form_entity_type != 'BLOCK',
            'consent-form-field-responsive': previewForm
        }]"
    >
        <!-- Dont show label for toggle  -->
        <!-- <div class="scroll-head absolute h-2 w-full"></div> -->
        <div class="flex mb-2 justify-between" v-if="showLabel && !getFieldConfig.customTemplate" >
            <div class="flex mr-1">
                <label for="#" class="form-label text-md font-medium inline-block text-base-content" :class="{responsiveLabel: previewForm}" >
                    <span :class='{"pfield":fieldData.type==="CANDIDATE_SIGNATUTRE"}'>{{ fieldData.label }}</span>
                    
                    <span class="ml-1 inline text-red-500 no-print" 
                        v-if="fieldData.mandatory || (this.validations && this.validations.value && this.validations.value.$params && this.validations.value.$params.regex)">
                        *
                    </span>
                </label>
            </div>

            <svg v-if="fieldData.description" :disabled="formAlreadySubmitted" :content="`<span class='break-words'>${fieldData.description}</span>`" v-tippy='{ arrow : true }' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" :class="{toolTipIcon : previewForm }" class="neo-form-builder__info w-5 h-5 text-primary ml-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
            </svg>


        </div>

        <template v-else-if="showLabel" class="consent-area" >
            <div
                v-if="getFieldConfig.customTemplate === 'consent'"
                class="consentBlock template flex items-start py-2 px-4 pr-1 border border-solid border-box-stroke rounded-md text-sm bg-white relative transition-all consent-field"
                :class="{ 'consentError': isError, consentBlockRes: previewForm }"
            >
                <div ref="consent-field-block" class="consent-field-block template__label w-full break-all pr-3 form-label inline-block leading-normal text-base-content max-h-36 overflow-auto scroll-bar transition-all" v-html="fieldData.placeholder" 
                ></div>
                <!-- <div class="fade-bottom bg-black absolute h-10 left-0 right-0 top-28 bottom-0 pointer-events-none"></div> -->
                
             <div class="consent-button " @click="expandConsent" >

                <button
                    class="show-button absolute consentViewButton transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 py-2 px-6
                        h-10 bg-blue-600 cursor-pointer hover:bg-blue-700   border-none rounded-lg text-base
                        text-white-text flex justify-center items-center font-semibold transition-all no-print"
                    title="Expand"
                   @click="expandConsent"
                   :class="{viewConsentBtn : previewForm}"
                    
                >
                    <!-- <expandIcon class="h-5 w-5 fill-current text-primary" /> -->
                   <span class="view-consent print--none" style="margin-top:0px">
                      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none" class="eye-icon">
                        <path d="M10.5003 2.5C14.9937 2.5 18.732 5.73313 19.5158 10C18.732 14.2668 14.9937 17.5 10.5003 17.5C6.00681 17.5 2.2685 14.2668 1.48474 10C2.2685 5.73313 6.00681 2.5 10.5003 2.5ZM10.5003 15.8333C14.03 15.8333 17.0503 13.3767 17.8148 10C17.0503 6.62336 14.03 4.16667 10.5003 4.16667C6.97054 4.16667 3.95022 6.62336 3.18569 10C3.95022 13.3767 6.97054 15.8333 10.5003 15.8333ZM10.5003 13.75C8.42919 13.75 6.75026 12.0711 6.75026 10C6.75026 7.92893 8.42919 6.25 10.5003 6.25C12.5713 6.25 14.2503 7.92893 14.2503 10C14.2503 12.0711 12.5713 13.75 10.5003 13.75ZM10.5003 12.0833C11.6509 12.0833 12.5836 11.1506 12.5836 10C12.5836 8.84942 11.6509 7.91667 10.5003 7.91667C9.34971 7.91667 8.41693 8.84942 8.41693 10C8.41693 11.1506 9.34971 12.0833 10.5003 12.0833Z" fill="#0D6AD5"/>
                      </svg> 
                      <span v-if="windowWidth > 600" class="mx-1 firstbtn landscape-notVisible">View Consent</span>
                      <span v-else class="landscape-notVisible">View Consent</span>
                      <span v-if="windowWidth<942 && windowWidth>600" class="landscape-visible">View Consent</span>
                    </span>
                </button>
              

           
                <!-- <button
                    class="show-button mobileConsentViewButton absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 py-2 px-6
                        h-10 bg-blue-600 cursor-pointer hover:bg-blue-700 border-none rounded-lg text-base
                        text-white-text flex justify-center items-center font-semibold transition-all"
                    title="Expand"
                    @click="expandConsent"
                    :class="{viewConsentBtn : previewForm}"
              v-else
                >
                   <span class="view-consent">
                      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none" class="eye-icon">
                        <path d="M10.5003 2.5C14.9937 2.5 18.732 5.73313 19.5158 10C18.732 14.2668 14.9937 17.5 10.5003 17.5C6.00681 17.5 2.2685 14.2668 1.48474 10C2.2685 5.73313 6.00681 2.5 10.5003 2.5ZM10.5003 15.8333C14.03 15.8333 17.0503 13.3767 17.8148 10C17.0503 6.62336 14.03 4.16667 10.5003 4.16667C6.97054 4.16667 3.95022 6.62336 3.18569 10C3.95022 13.3767 6.97054 15.8333 10.5003 15.8333ZM10.5003 13.75C8.42919 13.75 6.75026 12.0711 6.75026 10C6.75026 7.92893 8.42919 6.25 10.5003 6.25C12.5713 6.25 14.2503 7.92893 14.2503 10C14.2503 12.0711 12.5713 13.75 10.5003 13.75ZM10.5003 12.0833C11.6509 12.0833 12.5836 11.1506 12.5836 10C12.5836 8.84942 11.6509 7.91667 10.5003 7.91667C9.34971 7.91667 8.41693 8.84942 8.41693 10C8.41693 11.1506 9.34971 12.0833 10.5003 12.0833Z" fill="#0D6AD5"/>
                      </svg>
                      <span class="mx-1">View Consent</span>
                    
                    </span>
                </button> -->
             </div>
            </div>
        </template>

        <fieldset :disabled="!!isDisabled || disabled" class="fieldset" :class="{'formFieldFieldSet': previewForm}">
            <component
                ref="field"
                class="relative"
                :is="getFieldConfig.tag"
                :path="getPathString"
                v-bind="getFieldConfig.props"
                :fieldData="fieldData"
                :userData="userData"
                :parentData="parentData"
                :key="fieldData.field_id"
                v-model="fieldValue"
                :error="isError"
                :previewMode="previewMode"
                :previewForm="previewForm"
                :disabled="!!isDisabled || disabled"
                @nextSection="nextSection"
                @addTimeline="addTimeline"
                @removeTimeline="removeTimeline"
                :hasSignature='hasSignature'
                :class="{dynamicInputFields: previewForm}" 
                :notInsideBlock="notInsideBlock"
                @handleFieldValueChnage="handleFieldValueChnage"
                :formAlreadySubmitted="formAlreadySubmitted"
                :isCopyBlock="isCopyBlock"
                @checkCheckboxId="checkCheckboxId"
                :checkBoxId="checkBoxId"
                :isBlockDeleted="isBlockDeleted"
                @checkBoxData="checkBoxData"
                :hidden="hidden"
            />
        </fieldset>
        <small
            v-if="fieldData.ui_error && !isError && fieldData.is_field_saved"
            class="text-red-600 inline-block mt-2 text-sm"
        >
            {{ fieldData.ui_error_msg }}
        </small>
        <small class="text-gray-400 inline-block mt-2 text-sm" v-if="getHelpText" v-text="getHelpText" />
        <small
            v-if="fieldData.error_message"
            class="text-red-600 inline-block mt-2 text-sm"
        >
            {{ fieldData.error_message }}
        </small>
        <small
            v-if="isError"
            class="text-red-600 inline-block mt-2 text-sm flex flex-col"
        >
            <template v-for="(param, name) in validations.value.$params">
                <span
                    :key="name"
                    v-if="!validations.value[name] && param.$message"
                >
                    {{param.$message}}
                </span>
            </template>
        </small>
        <div class="justify-between" v-if="showLabel && !getFieldConfig.customTemplate && fieldData?.options?.length > 0 && fieldValue" >
            <div class="w-full mr-1" v-for="(field,index) in fieldData.options"  :key="index">
                <!-- TODO: provide optionData="field", fieldData="fieldData" -->
                <cascading
                    v-if="field?.cascading_fields?.length > 0"
                    :fieldData="field"
                    :selectedFieldValue = "fieldValue"
                    :userData="userData"
                    :parentData="parentData"
                    :validations="getCascadingValidations(index)"
                    :path="[
                        ...path,
                        { field: 'field_fe_id', value: field.field_fe_id || field.field_id },
                    ]"
                    :previewMode="previewMode"
                    :previewForm="previewForm"
                    class="my-4"
                    :class="{dynamicFields: previewForm}"
                    :notInsideBlock="true"
                    :formAlreadySubmitted="formAlreadySubmitted"
                    :hidden="hidden"
                />
            </div>
        </div>

        <div v-if="isCopy || isMulti" class="flex justify-end gap-3 mt-1">
            <button
                v-if="isCopy && !formAlreadySubmitted"
                class="h-6 border border-gray-700 w-16 min-w-min px-2 hover:bg-gray-100 cursor-pointer rounded-md text-xs text-black flex justify-center items-center font-semibold"
                @click="removeEntity" :disabled="formAlreadySubmitted"
            >Remove</button>
            <button
                v-if="isMulti && !formAlreadySubmitted"
                class="h-6 border border-gray-700 w-16 min-w-min px-2 bg-gray-600 hover:bg-gray-700 cursor-pointer rounded-md text-xs text-white-text flex justify-center items-center font-semibold disabled:opacity-50"
                @click="copyEntity()"
                :disabled="previewForm || formAlreadySubmitted "
                :class="{newInput_add: previewForm}"
            >Add</button>
        </div>
    </div>
</template>

<script>
import { EventBus } from "@/main.js";
import store from "../store";
import { getFieldById, getEntityByKeyList } from "../utils";
import { getCascadingFields } from "@shared/components/form-builder/utils";
import fieldTypes from "../utils/field-types";
import { validateEmail } from "@shared/plugins/functions.js";
import cascading from "./cascadingFields.vue"
// import expandIcon from "@shared/assets/arrows-maximize.svg";

// TODO get rid of 'path' usage. Use direct links to objects instead

export default {
    components: {
        "cascading": cascading,
    //     expandIcon,
    },
    props: {
        parentData: {
            type: Object,
            require: true,
        },
        fieldData: {
            type: Object,
            require: true,
        },
        path: Array,
        // Used to determin if field can be copied. Is false when field is multi but can't be copied anymore:
        isMulti: {
            type: Boolean,
            default: false,
        },
        validations: {
            type: Object,
            default: () => null,
        },
        // is used to preview form before creating it in an admin panel:
        previewMode: {
            type: Boolean,
            default: false
        },
        // is used to prview filled form before its submitting:
        previewForm:{
            type: Boolean,
            default: false
        },
        linkedBlock: {
            type: Object,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        userData: {
            type: Object,
            default:()=> {}
        },
        hasSignature:{
            type: Boolean
        },
        notInsideBlock:{
            type: Boolean
        },
        formAlreadySubmitted: {
            type: Boolean
        },
        isCopyBlock: {
            type: Boolean
        },
        checkBoxId: {
            type: String
        },
        isBlockDeleted: {
            type: Boolean,
        },
        hidden: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            fieldTypes,
            customTemplates: ['consent', 'label'],
            store,
            windowWidth: 0,
            windowHeight: 0,
            dateRangeOptions:[],
            value:"",
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleWindowResize);
        this.getWindowSize();
        this.value = this.fieldData?.value
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    },
    created() {
        // check if field uses value of another field:
        if (this.usedFromField) this.fieldData.value = this.usedFromFieldValue;
        // or if there is the same field in linked block:
        else if (this.linkedBlockField) this.fieldData.value = this.linkedBlockFieldValue;
        // check if field is copy and has related field and set additional relation configs:
        if (this.isRelated) {
            const compareParams = {
                // search by original field_id prop:
                field_id: this.fieldData.condition.field_id,
                // TODO when we copy field we have different fields with same field_id. Should be handled otherwise the first match will be used
            }
            // if field is copy search for field with the same copy id:
            if (this.fieldData.copy_id) compareParams.copy_id = this.fieldData.copy_id;
            // or search for field within the same block/section:
            else compareParams.pathParentString = this.fieldData.pathParentString;
            const relatedField = getEntityByKeyList(this.sections_data, compareParams);
            this.$set(this.dependencies[this.fieldData.pathString], 'related_field', relatedField);
            this.$set(this.dependencies[this.fieldData.pathString], 'hidden', !this.isShown);
        }
        // if field is used for gap validation create a gap group in store:
        if (this.fieldData.gap_validation) {
            if (!this.gapGroup) this.gapGroup = {
                block_id: this.parentData.block_fe_id,
                field_id: this.fieldData.field_id,
                timeline: [],
            }
            if (!this.gapGroup.timeline.some(el => el.field?.field_id === this.fieldData?.field_id)) {
                this.gapGroup.timeline.push({
                    field: this.fieldData,
                    block: this.parentData,
                })
            }
        }
    },
    watch: {
        isShown(val) {
            if (this.isRelated) this.$set(this.dependencies[this.fieldData.pathString], 'hidden', !val);
            // reset field value if it was removed from the form:
            // if (!val) this.fieldValue = null;
            this.value = this.fieldData?.value ? this.fieldData?.value : this.value
            this.fieldValue = val ? this.value : null;
        },
        usedFromFieldValue(val) {
            this.fieldValue = val;
        },
        linkedBlockFieldValue(val) {
            this.fieldValue = val;
        },
    },
    computed: {
        getPathString() {
            return this.path.map(el => el.value).join('/');
        },
        isFieldMulti() {
            return this.fieldData.multi;
        },
        showLabel(){
            return this.getFieldType !== 'toggle' && this.getFieldType !== 'label' && this.getFieldType !== 'linked button'
        },
        sections_data() {
            return store.list;
        },
        dependencies() {
            return store.dependencies;
        },
        // * Here, the related field is the field-influencer which value define if the current field is shown
        isRelated() {
            return Boolean((this.fieldData.pathString in this.dependencies) && this.fieldData.condition);
        },
        dependencyData() {
            return this.dependencies[this.fieldData.pathString];
        },
        // related field is a field which value determine if the current field is shown:
        relatedField() {
            return this.dependencyData?.related_field;
        },
        isRelatedShown() {
            // if field isn't dependent or related field isn't dependent we don't need to check this:
            if (!this.isRelated || !this.dependencies[this.relatedField.pathString]) return true;
            return !this.dependencies[this.relatedField.pathString].hidden;
        },
        isShown() {
            if (!this.fieldData.visible) return false;
            if (this.isRelated) {
                return this.relatedField?.value === this.dependencyData.condition.dependence_value && this.isRelatedShown;
            } else return true;
        },
        // isUsedFrom define if the value of the current field should be the same with another field:
        isUsedFrom() {
            return !!this.fieldData.use_value_from;
        },
        isDisabled() {
            return (this.isUsedFrom && this.fieldValue) || !this.fieldData.editable || this.linkedBlockField || this.previewForm;
        },
        usedFromField() {
            if (!this.isUsedFrom) return null;
            // search by field_fe_id because only original field has the same field_id and field_fe_id
            return getFieldById(this.sections_data, this.fieldData.use_value_from, 'field_fe_id');
        },
        usedFromFieldValue() {
            return this.usedFromField?.value;
        },
        // we also use fields from linked block as the used_from field because we use theirs values in the same way:
        linkedBlockField() {
            if (!this.linkedBlock) return null;
            return getEntityByKeyList(this.linkedBlock.entities, { field_internal_name: this.fieldData.field_internal_name, index: this.fieldData.index });
        },
        linkedBlockFieldValue() {
            return this.linkedBlockField?.value;
        },
        fieldValue: {
            get() {
                // console.log("his.fieldData.value ", this.fieldData.value)
                return this.fieldData.value;
            },
            set(value) {
                if (this.previewForm) return;
                // EventBus.$emit("changeFieldValue", { path: this.path, value, type: this. getFieldBaseType || this.getFieldType });
                // update field using refference to the field in sections_data:
                this.fieldData.value = value;
                this.fieldData.error_message = '';
                // update used_from field if exists:
                if (this.usedFromField) {
                    this.usedFromField.value = value;
                    this.usedFromField.error_message = '';
                }
            }
        },
        getFieldType() {
            return this.fieldData?.field_type?.toLowerCase();
        },
        getFieldBaseType() {
            return this.fieldData?.field_base_type?.toLowerCase();
        },
        getHelpText() {
            return this.fieldData?.description_text ?? "";
        },
        // getComponentField() {
            //     return this.getFieldType + '-field'
        // },
        getFieldConfig() {
            return this.fieldTypes[this.getFieldType] || this.fieldTypes[this.getFieldBaseType];
        },
        isError() {
            return this.validations?.value?.$error || !!this.fieldData.error_message;
        },
        isCopy() {
            // fields in copied block have new field_fe_id that aren't equal to field_id.
            // return (this.isFieldMulti && this.fieldData.field_id !== this.fieldData.field_fe_id) || this.fieldData.is_copy;
            // So there is a new logic for copy determination:
            // find a first field with the same field_id and compare with current:
            const firstField = getFieldById(this.parentData.entities, this.fieldData.field_id);
            return firstField.field_fe_id !== this.fieldData.field_fe_id;
        },
        isSavedCopy() {
            // is_copy is a temporary frontend property
            return this.isCopy && !this.fieldData.is_copy;
        },
        gapGroup: {
            get() {
                return store.gap_values[this.parentData.block_id] || null;
            },
            set(val) {
                this.$set(store.gap_values, this.parentData.block_id, val);
            },
        },
    },
    methods: {
        validateEmail,
        handleFieldValueChnage(event, valueType) {
            this.handlecascadingFields()
            if (this.fieldData.field_type == 'Checkbox') {
                    let value = event.target.value;
                    if(this.fieldData.value && this.fieldData.value?.length > 0) {
                        this.fieldData?.value.map(el => {
                            this.dateRangeOptions?.push(el.id);
                        })
                    }
                    this.dateRangeOptions = event.target.checked ? [...this.dateRangeOptions, value] : this.dateRangeOptions.filter((id) => id != value)
                    if (this.dateRangeOptions.length) {
                        this.fieldData.ui_error = false;
                        this.fieldData.ui_error_msg = '';
                    }
                    else {
                        this.fieldData.ui_error = true;
                        this.fieldData.ui_error_msg = 'This field is required!';
                    }
                }


            else if (this.fieldData.field_type == 'Signature') {
                if (event && valueType == 'signed') {
                    this.fieldData.ui_error = false;
                    this.fieldData.ui_error_msg = '';
                } else if (event && valueType == 'uploaded') {
                    this.fieldData.ui_error = false;
                    this.fieldData.ui_error_msg = '';
                } else if (!event || event == undefined) {
                    this.fieldData.ui_error = true;
                    this.fieldData.ui_error_msg = 'This field is required!';
                }
            }
            else if (this.fieldData.field_type == 'File') {
                if (event && event?.length > 0) {
                    this.fieldData.ui_error = false;
                    this.fieldData.ui_error_msg = '';
                } else {
                    this.fieldData.ui_error = true;
                    this.fieldData.ui_error_msg = 'This field is required!'; 
                }
            }
            else if (this.fieldData.field_type == 'Candidate Consent') {
                if (event && event.target.value == 'disagree') {
                    this.fieldData.ui_error = true;
                    this.fieldData.ui_error_msg = 'You need to agree to the consent by selecting yes to proceed forward'; 
                } else if (event && event != 'disagree') {
                    this.fieldData.ui_error = false;
                    this.fieldData.ui_error_msg = '';
                } else {
                    this.fieldData.ui_error = true;
                    this.fieldData.ui_error_msg = 'This field is required!';
                }
            }
            else if (this.fieldData.field_type == 'Multi select') {
                if (event && event?.length > 0) {
                    this.fieldData.ui_error = false;
                    this.fieldData.ui_error_msg = ''; 
                } else {
                    this.fieldData.ui_error = true;
                    this.fieldData.ui_error_msg = 'This field is required!';
                }
            }
            else if (this.fieldData?.field_type == 'Date Range (Timeline)') {
                if (event.startDateValue && event.endDateValue) {
                    this.fieldData.ui_error = false;
                    this.fieldData.ui_error_msg = ''; 
                } else {
                    this.fieldData.ui_error = true;
                    this.fieldData.ui_error_msg = 'This field is required!';
                }
            }
            else if (event && event != null && event != undefined) {
                if (this.fieldData.field_type == 'Email' && !validateEmail(event)) {
                    this.fieldData.ui_error = true;
                    this.fieldData.ui_error_msg = 'Please enter a valid mail!';
                } else {
                    this.fieldData.ui_error = false;
                    this.fieldData.ui_error_msg = '';
                }
            } else if (this.fieldData.is_field_saved) {
                this.fieldData.ui_error = true;
                this.fieldData.ui_error_msg = 'This field is required!';
            }
            this.$emit("haveBlockErrors");
        },
        handlecascadingFields(){
            this.fieldData.options.map(opt => {
                     if(this.fieldValue != opt.option_value){
                        this.handleValues(opt)
                     }
            })
            
        },
        handleValues(opt){
            if(opt?.options?.length>0){
                opt.options.map(el=> {
                    {el.value="";
                        el.cascading_fields?.map(opt => {
                        opt.value = "";
                        if(opt.options.length>0){
                            this.handleValues(el)
                        }
                        })
                    }
                })
            }
            else if(opt.cascading_fields?.length>0){
                opt.cascading_fields?.map(el => {
                    el.value = "";
                    if(el.options.length>0){
                        this.handleValues(el)
                    }
                })
            }
        },
        copyEntity(previous = this.fieldData) {
            return this.store.utils.copyEntity({
                entity: this.fieldData,
                list: this.parentData.entities,
                values: { field_serial: this.fieldData.field_serial + 1 },
                previous: previous,
            });
        },
        async removeEntity() {
            try {
                await this.store.utils.removeEntity({
                    entity: this.fieldData,
                    list: this.parentData.entities,
                    is_saved: this.isSavedCopy,
                    form_entity_type: this.fieldData.form_entity_type,
                });
            } catch (error) {
                console.log('can\'t remove field', error)
            }
        },
        expandConsent() {
            this.$refs.field.showExpandModal();
        },
        nextSection() {
            EventBus.$emit("nextSection");
        },
        addTimeline() {
            this.$emit('addTimeline');  
        },
        removeTimeline() {
          this.$emit('removeTimeline');  
        },
        handleWindowResize() {
            this.getWindowSize();
        },
        checkCheckboxId(val){
            this.$emit('checkCheckboxId',val);
        },
        getWindowSize() {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        },
        checkBoxData(val) {
            this.$emit('checkBoxData', val);
        },
        getCascadingValidations(index) {
            const cascadingFields = getCascadingFields(this.fieldData)
            if (!cascadingFields || !this.validations) return null
            return this.validations.options?.[index]
        },
},
};



</script>

<style lang="scss">

.form-field {
//     &--invalid {
//         ::v-deep {
//             input, select, textarea, .multiselect__tags {
//                 @apply border-red-600;
//             }
//         }
//     }
&--consent{
    template{
            .template__label{
                overflow:break-word;
                word-wrap:break-word;
                hyphens:auto !important;

            }

}
}
.form-label, .consent-content {
    a {
        @apply text-primary;
        text-decoration: underline;
        cursor: pointer;
    }
        h1 {
            font-size: 2em;
        }
        h2 {
            font-size: 1.5em;
        }
        h3 {
            font-size: 1.17em;
        }
        h4 {
            font-size: 1.00em;
        }
        h5 {
            font-size: 0.83em;
        }
        h6 {
            font-size: 0.67em;
        }
    }
    ul, ol {
        padding-left: 3em;
    }
    ul {
        li {
            list-style: disc;
        }
    }
    ol {
        li {
            list-style: auto;
        }
    }
}

   


</style>
<style lang="scss" scoped>
.consentViewButton{
    background: none !important;
}
.consentError{
    border-color:rgba(220,38,38,var(--tw-border-opacity)) !important;
}

// .form-field{
//     width:100% !important;
//     margin: auto;
// }

.form-field {
    &--consent {
        .template {
            width:100%;
            .show-button{
                opacity: 0 !important;
                pointer-events: none;
            }

            &:hover {
                .show-button{
                    opacity: 1 !important;
                    pointer-events: initial;
                }
                .template__label {
                    // filter: blur(2px) !important;
                }
            }
        }
    }
}


.consent-button{
    cursor:pointer;
}

// .fade-bottom {
//     background: linear-gradient(to top, #fff 20%, transparent);
// }
::v-deep {
    input, textarea, select {
        &:not(.filepond--browser) {
            &:disabled {
                @apply opacity-80 text-gray-600;
                cursor: not-allowed;
            }
        }
    }
   
}
.neo-form-builder__info {
    min-width: 50px;
    margin: 0;
    color: black !important;
}

.consentViewButton{
    margin:auto;
    text-align:center;
    & span{
      margin-top:-5%;
    }
    & span > span{
    display:flex;
    align-items:center;
    justify-content:center;
    margin-top: -2px;
    }
}

 .mobileConsentViewButton .eye-icon{
    margin-top:1.5% !important;
}


@media (min-width: 300px) and (max-width: 500px) and (max-height:1400px){
    .mobileConsentViewButton{
        display: flex;
        //line-height: 20px;
        width: 100%;
        font-size: 15px;
        margin:0 auto auto auto;
        background-color:white;

    }
    

    .neo-form-builder__info {
        color: black !important;
        min-width: 50px;
        margin: 0;
         color: black !important;
    }
    
    label{
        width:100% !important;
    }

    
    .consent-field-block{
    margin:10px auto auto -1.7% !important;
    }
   
}

@media (min-width: 300px) and (max-width: 420px) and (max-height:1400px){
    .consent-field-block{
    margin:10px auto auto -2% !important;
    }
}
@media (max-width:1080px){
    .scrollToElement{
        // -webkit-scroll-margin-top: 4rem;
        // scroll-margin-top: 4rem;
    }
    .scroll-head{
        top: -4rem;
    }
}
@media (max-width:940px) and (max-height:1400px){
     
    .form-field{
        margin-bottom: 25px !important;
    }
.form-field {
    &--consent {
        .template {
            .show-button {
                opacity:1 !important;
                pointer-events: none;
            }
            &:hover {
                .show-button {
                    opacity: 1;
                    pointer-events: initial;
                }
                .template__label {
                    filter: none !important;
                }
            }
        }
    }
}



 .form-field .firstbtn{
       margin-top:0.1%;
    }
    .consentViewButton .eye-icon{
        margin-top:0.8%;
    }

.mobileConsentViewButton,.consentViewButton {
        display: flex;
        width: 100%;
        font-size: 15px;
        margin:0px auto auto auto !important;
        background-color:white;
        color:#0D6AD5;
    }

.consentViewButton{
    padding-top:6% !important;
}

.consent-button button{
   position:relative;
   display:flex;
   margin:auto;
}

.consent-button{
    height:20px !important;
    width:100%;
    padding:0px;
     margin: 4% auto 4% auto;
}

.consentBlock{
    display:flex;
    flex-direction:column;
    align-items:center !important;
    justify-content:center;
    margin:auto;
    cursor:pointer;
     padding:0.5rem;
    // padding: 0.5rem;
}


.consent-field-block{
    padding:3px 8px 8px 8px !important;
    // margin:10px auto auto -1.3%;
    margin: auto !important;
    align-items:center;
    justify-content:center;
    line-height:24px;
    width:100%;
    border:1px solid #DBDDF1;
}

.view-consent{
    display:flex;
}
      
}

@media screen and (min-width:769px) and (max-width:940px) and (max-height:1440px){

.consent-field-block{
    padding:3px 8px 8px 8px !important;
    margin:10px auto auto -1%;
    align-items:center;
    justify-content:center;
    line-height:24px;
    width:100%;
     border:1px solid #DBDDF1;
}
      
}

@media screen and (min-width:941px){

.eye-icon {
    color:white !important;
    z-index:10;
} 
svg {
    color:white !important;
} 


.form-field{
&--consent {
        .template {
            .show-button {
                opacity:0 !important;
            }
                .show-button{
                    opacity: 1 !important
                
                }
                .template__label {
                    // filter:blur(2px) !important;
                }
            
        }
    }
} 



.consent-field-block{
    border:1px solid #DBDDF1;
    padding:3px 8px 8px 8px !important;
    margin:auto auto auto auto;
    align-items:center;
    width:100%;
    line-height:24px;
    overflow:break-word;
    word-wrap:break-word;
    hyphens:auto;

    &:hover{
        // filter:blur(2px) !important;
    }
}



.consent-button{
    display:flex;
    position:absolute;
    width:100%;
    //margin:5% 27% 5% 32% !important;
    justify-content:center;
    height: 2.8em;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    & button{
        cursor:pointer !important;
        width:260px;
    }
}



.consent-button button{
      height:2.9em;
      display:flex;
    margin:auto;
    & .eye-icon{
        color:white !important;
        position:absolute;
     }

    & span{
        margin-top:1%;
        & span{
            margin:auto auto auto 20px;
        }
    }
}

    .consent-button button{
    position: relative;
    display: flex;
    }
    .consent-button {
    height: 10px !important;
    width: 100%;
    padding: 0px;
    position: relative;
    display: flex;
    margin: 16px auto;
    opacity: 1 !important;
    }
    .consentBlock {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center;
    margin: auto;
    cursor: pointer;
    padding: 0.5rem 0.5rem 0 0.5rem;
    border-radius: 10px;
    }
    .consentViewButton{
        display: flex;
        width: 100%;
        font-size: 15px;
        margin:0px auto auto 2px !important;
        background-color:white;
        color:#0D6AD5;
        padding: 0;
        height: auto !important;
        position: relative;
        background: none;
    }

    .consent-field-block{
        border: 1px solid #DBDDF1;
        padding: 6px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

    }
    .consent-button button span span{
        margin: auto auto auto 23px;
        width: 200px;
    }

.view-consent{
    display: flex;
    align-items: center;
    width: 145px;
    justify-content: space-between;
}


}


// Media query from 1490px to 2200px


@media screen and (min-width:1470px) and (max-width:2200px) and (max-height:1400px){
//    .eye-icon,svg{
//     color:white !important;
//     z-index:10;
// }



// .form-field {
//     &--consent {
//         .template {
//             .show-button{
//                 opacity: 1 !important;
//             } 

//         &:hover {
//                 .show-button{
//                     opacity: 1 !important;
//                 }
//                 .template__label {
//                     // filter: blur(2px);
//                 }
//     }
// }

//     }
// }
 
// .consent-button{
//     display:flex;
//     position:absolute;
//     width:100%;
//     //margin:5% 27% 5% 32% !important;
//     justify-content:center;
//     left: 50%;
//     top: 50%;
//     transform: translate(-50%, -50%);
//     height: 2.8em;
//     & button{
//         cursor:pointer !important;
//         width:260px;
//     }
// }



// .consent-button button{
//       height:2.9em;
//       display:flex;
//     margin:auto;
//     & .eye-icon{
//         color:white !important;
//         position:absolute;
//      }

//     & span{
//         margin-top:1%;
//         & span{
//             margin:auto auto auto 20px;
//         }
//     }
// }

// .consentBlock{
//     flex-direction: column;
// }
// .consent-button{
//     position: relative;
//     padding: 0 5px;
// }
// .consent-button button span span{
//     padding: 0 0 0 5px;
// }
// .consentViewButton{
//     position: relative;
//     background: none;
//     color: #3C86DC;
//     justify-content: start;
//     position:absolute;
//     width: auto;
// }
// .view-consent{
//     display: flex;
//     align-items: center;
// }
// .consent-field-block{
//         border: 1px solid #DBDDF1;
//     }

}
.landscape-visible{
        display: none !important;
    }

@media screen and (min-width: 600px) and (max-width: 942px) and (orientation: landscape){
    .landscape-visible{
        display: block !important;
    }
    .landscape-notVisible{
        display: none !important;
    }
    .consent-button{
        margin:2% auto !important;
    }
    .consentViewButton span{
        // margin-top: -5% !important;;
    }
    .consentViewButton{
        padding-top: 0 !important;
    }
    .consentViewButton>span{
        margin-top: 16px !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 768px){
    .dynamicInputFields ,.formFieldFieldSet select, .formFieldFieldSet div{
        border-radius: 10px !important;
    }
}

@media screen and (min-width: 360px) and (max-width: 768px){
    .viewConsentBtn{
        height:20px;
    }
}

.newInput_add{
    display:none;
}
@media screen and (min-width: 360px) and (max-width: 487px){
    .consentViewButton{
        margin-top: 0% !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 359px){
    .dynamicInputFields{
        width: 190px;
        border:none !important;
    }
    .formFieldFieldSet select, .formFieldFieldSet div{
        width: 190px !important;
    }
}

@media screen and (min-width: 360px) and (max-width: 414px){
    .dynamicInputFields{
        width: 210px;
        border:none !important;
    }
    .formFieldFieldSet select, .formFieldFieldSet div{
        width: auto !important;
        margin: 0;
    }
    .formFieldFieldSet input{
        margin: 0;
    }
}

@media screen and (min-width: 550px) and (max-width: 1080px) and (orientation: landscape) {
    .dynamicInputFields{
        width: 400px !important;
        border:none !important;
    }
    .formFieldFieldSet select, .formFieldFieldSet div{
        width: 400px !important;
        margin: 0;
    }
    .formFieldFieldSet div{
        padding: 0;
    }
    .formFieldFieldSet input{
        margin: 0;
    }
}

@media screen and (min-width: 501px) and (max-width: 768px){
    .dynamicInputFields{
        width: 400px !important;
        border:none !important;
    }
    .formFieldFieldSet select, .formFieldFieldSet div{
        width: 400px !important;
        margin: 0;
    }
    .formFieldFieldSet div{
        padding: 0;
    }
    .formFieldFieldSet input{
        margin: 0;
    }
    
}

    
</style>